import React from 'react'
import './App.css'
import { AppRouter } from './routers/AppRouter'

export const App = () => {
  return (
    <AppRouter/>
  )
}

